import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/reactKwik/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "audit--formal-verification",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#audit--formal-verification",
        "aria-label": "audit  formal verification permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Audit & Formal Verification`}</h1>
    <p>{`Between January 8 and April 30, a team of six engineers reviewed and formally verified crucial components of the smart contracts for Kwikswap.`}</p>
    <p>{`Their past work includes smart contract development on and formal verification of multi-collateral DAI.`}</p>
    <p>{`The scope of work includes:`}</p>
    <ul>
      <li parentName="ul">{`Formal verification of the core smart contracts`}</li>
      <li parentName="ul">{`Code review of core smart contracts`}</li>
      <li parentName="ul">{`Numerical error analysis`}</li>
      <li parentName="ul">{`Code review of periphery smart contracts (during ongoing development)`}</li>
    </ul>
    <h1 {...{
      "id": "bug-bounty",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#bug-bounty",
        "aria-label": "bug bounty permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Bounty`}</h1>
    <p>{`Kwikswap has an open and ongoing bug `}<a parentName="p" {...{
        "href": "/bug-bounty"
      }}>{`bounty program`}</a>{`.`}</p>
    <h1 {...{
      "id": "considerations-when-building-on-kwikswap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#considerations-when-building-on-kwikswap",
        "aria-label": "considerations when building on kwikswap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Considerations when building on Kwikswap`}</h1>
    <p>{`When integrating Kwikswap into another on-chain system, particular care must be taken to avoid security vulnerabilities, avenues for manipulations, and the potential loss of funds.`}</p>
    <p>{`As a preliminary note: smart contract integrations can happen at two levels: directly with `}<Link to='/docs/v1/smart-contracts/pair' mdxType="Link">{`Pair`}</Link>{` contracts, or through the `}<Link to='/docs/v1/smart-contracts/router02' mdxType="Link">{`Router`}</Link>{`. Direct interactions offer maximal flexibility, but require the most work to get right. Mediated interactions offer more limited capabilities, but stronger safety guarantees.`}</p>
    <p>{`There are two primary categories of risk associated with Kwikswap. The first involves so-called “static” errors: accidentally sending too many tokens to a pair during a swap (or requesting too few tokens back), allowing transactions to linger in the mempool long enough for the sender’s expectations about prices to no longer be true, etc. These errors are typically addressed with fairly straightforward logic checks. `}<em parentName="p">{`Performing these checks is the primary reason for the existence of routers`}</em>{`. Those who interact directly with pairs must perform these checks themselves (with the help of the `}<Link to='/docs/v1/smart-contracts/library' mdxType="Link">{`Library`}</Link>{`).`}</p>
    <p>{`The second risk category is “dynamic”, and involves runtime pricing. Because Ethereum transactions occur in an adversarial environment, naively written smart contracts `}<em parentName="p">{`can be exploited for profit`}</em>{`. For example, if a smart contract checks the asset ratio in a Kwikswap pool at runtime and trades against it, assuming that the ratio represents the “fair” or “market” price of these assets, `}<em parentName="p">{`it is highly vulnerable to manipulation`}</em>{`. A malicious actor could e.g. trivially insert transactions before and after the naive transaction (a so-called “sandwich” attack) causing the smart contract to trade at a radically worse price, profit from this at the trader’s expense, and then return the contracts to their original state, all at a low cost. (One important caveat is that these types of attacks are mitigated by trading in extremely liquid pools, and/or at low values.)`}</p>
    <p>{`The best way to protect against these attacks is to introduce a price oracle. The best “oracle” is simply `}<em parentName="p">{`traders’ off-chain observation of the prevailing price`}</em>{`, which can be passed into the trade as a safety check. This strategy is best suited to retail trading venues `}<em parentName="p">{`where users initiate trades on their own behalf`}</em>{`. However, it is often the case that a trusted price observation cannot be obtained (e.g. in multi-step, programmatic interactions involving Kwikswap). Without a price oracle, these interactions are forced to trade at whatever the (potentially manipulated) rate on Kwikswap is. However, an oracle can bound manipulation, and is a sine qua non. Determining the ideal oracle for a given setting is out of scope, but for details on the Kwikswap approach to oracles, see `}<Link to='/docs/v1/core-concepts/oracles' mdxType="Link">{`Oracles`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      